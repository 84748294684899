import HomeTemplate from '../../Templates/Home';
import PropTypes from 'prop-types';
import React from 'react';

const Home = (props) => {
  const {
    footerDescription,
    footerCopyrightButtonLabel,
    footerCopyrightButtonOnClick,
    footerCopyrightDescription,
    footerNetworks,
    footerTitle,
    headerAvatarSrc,
    headerMenuItems,
    headerProfileJob,
    headerProfileOnClick,
    headerProfileName,
    sectionAboutButtonLabel,
    sectionAboutButtonOnClick,
    sectionAboutDescription,
    sectionAboutImageSource,
    sectionAboutTitle,
    sectionContactAddress,
    sectionContactEmail,
    sectionContactEmailSubject,
    sectionContactMobileNumber,
    sectionContactTitle,
    sectionExperienceDescription,
    sectionExperienceTitle,
    sectionProjectsItems,
    sectionProjectsTitle,
    sectionServicesItems,
    sectionServicesTitle,
    sectionTestimonialsItems,
    sectionTestimonialsTitle,
  } = props;

  return (
    <HomeTemplate
      headerAvatarSrc={headerAvatarSrc}
      headerMenuItems={headerMenuItems}
      headerProfileJob={headerProfileJob}
      headerProfileOnClick={headerProfileOnClick}
      headerProfileName={headerProfileName}
      sectionAboutButtonLabel={sectionAboutButtonLabel}
      sectionAboutButtonOnClick={sectionAboutButtonOnClick}
      sectionAboutDescription={sectionAboutDescription}
      sectionAboutImageSource={sectionAboutImageSource}
      sectionAboutTitle={sectionAboutTitle}
      sectionExperienceDescription={sectionExperienceDescription}
      sectionExperienceTitle={sectionExperienceTitle}
      sectionServicesItems={sectionServicesItems}
      sectionServicesTitle={sectionServicesTitle}
      sectionTestimonialsItems={sectionTestimonialsItems}
      sectionTestimonialsTitle={sectionTestimonialsTitle}
      sectionProjectsItems={sectionProjectsItems}
      sectionProjectsTitle={sectionProjectsTitle}
      sectionContactAddress={sectionContactAddress}
      sectionContactEmail={sectionContactEmail}
      sectionContactEmailSubject={sectionContactEmailSubject}
      sectionContactMobileNumber={sectionContactMobileNumber}
      sectionContactTitle={sectionContactTitle}
      footerDescription={footerDescription}
      footerNetworks={footerNetworks}
      footerTitle={footerTitle}
      footerCopyrightButtonLabel={footerCopyrightButtonLabel}
      footerCopyrightButtonOnClick={footerCopyrightButtonOnClick}
      footerCopyrightDescription={footerCopyrightDescription}
    />
  );
};

Home.propTypes = {
  footerDescription: PropTypes.string,
  footerCopyrightButtonLabel: PropTypes.string,
  footerCopyrightButtonOnClick: PropTypes.func,
  footerCopyrightDescription: PropTypes.node,
  footerNetworks: PropTypes.array,
  footerTitle: PropTypes.string,
  headerAvatarSrc: PropTypes.string,
  headerMenuItems: PropTypes.array,
  headerProfileJob: PropTypes.string,
  headerProfileOnClick: PropTypes.func,
  headerProfileName: PropTypes.string,
  sectionAboutButtonLabel: PropTypes.string,
  sectionAboutButtonOnClick: PropTypes.func,
  sectionAboutDescription: PropTypes.string,
  sectionAboutImageSource: PropTypes.string,
  sectionAboutTitle: PropTypes.string,
  sectionContactAddress: PropTypes.string,
  sectionContactEmail: PropTypes.string,
  sectionContactEmailSubject: PropTypes.string,
  sectionContactMobileNumber: PropTypes.string,
  sectionContactTitle: PropTypes.string,
  sectionExperienceDescription: PropTypes.string,
  sectionExperienceTitle: PropTypes.string,
  sectionProjectsItems: PropTypes.array,
  sectionProjectsTitle: PropTypes.string,
  sectionServicesItems: PropTypes.array,
  sectionServicesTitle: PropTypes.string,
  sectionTestimonialsItems: PropTypes.array,
  sectionTestimonialsTitle: PropTypes.string,
};

export default Home;
