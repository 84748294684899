import React, { Fragment } from 'react';
import { Trans, useTranslation } from 'react-i18next';

import { Helmet } from 'react-helmet';
import { Home as HomePage } from '../../components';
import _ from 'lodash';
import { animateScroll as scroll } from 'react-scroll';

const Home = () => {
  const [t, i18n] = useTranslation('translations');

  const nbYears = new Date().getFullYear() - 2007;

  const scrollToElement = ({ event, id }) => {
    event.preventDefault();

    if (!id) {
      return null;
    }

    scroll.scrollTo(
      document.getElementById(id).offsetTop -
        document.getElementById('header').offsetHeight,
    );
  };

  const sectionTestimonialsItemsI18n = i18n.language.includes('fr')
    ? [
        {
          authorAvatar:
            'https://static.colindavid.net/0001/testimonials/dimitri-le-roch.jpg',
          authorJob: 'Freelance Art Director, UX/UI designer',
          authorName: 'Dimitri Le Roch',
          text:
            'David est certainement un acharné du travail à la qualité impeccable. Soucieux du moindre pixel, il montre chaque jour son effroyable rigueur dans les divers projets qu’il mène avec passion et enthousiasme.<br /><br />Un profil d’exception, développeur front-end et développeur back-end, David est l’élément indispensable de votre équipe pour donner vie à vos projets digitaux.',
        },
        {
          authorAvatar:
            'https://static.colindavid.net/0001/projects/13-rounds-score-sheet-watchicon.png',
          authorJob: 'Application mobile',
          authorName: '13 Rounds Score Sheet',
          text:
            '<b>Sébastien BARUT</b><br>Vraiment une super app pour le yahtzee, joueurs réutilisables, historique et stats... Du bon boulot avec une interface agréable',
        },
        {
          authorAvatar:
            'https://static.colindavid.net/0001/projects/13-rounds-score-sheet-watchicon.png',
          authorJob: 'Application mobile',
          authorName: '13 Rounds Score Sheet',
          text:
            '<b>Kevin Labory</b><br>Génial pour jouer avec les enfants. J’adore !',
        },
        {
          authorAvatar:
            'https://static.colindavid.net/0001/projects/13-rounds-score-sheet-watchicon.png',
          authorJob: 'Application mobile',
          authorName: '13 Rounds Score Sheet',
          text:
            '<b>arnaudMano</b><br>C’est l’application idéale quand il te manque un papier et un stylo pour une partie improvisée...',
        },
      ]
    : [
        {
          authorAvatar:
            'https://static.colindavid.net/0001/projects/13-rounds-score-sheet-watchicon.png',
          authorJob: 'Mobile Application',
          authorName: '13 Rounds Score Sheet',
          text:
            '<b>Paul Hawkins</b><br>Great app for keeping yatzee score. Miles ahead of the rest. I can’t believe it is free. For a Yatzee fan this is a gift!!!!',
        },
        {
          authorAvatar:
            'https://static.colindavid.net/0001/projects/13-rounds-score-sheet-watchicon.png',
          authorJob: 'Mobile Application',
          authorName: '13 Rounds Score Sheet',
          text:
            '<b>Annie Krajewski</b><br>I don’t usually give reviews and I am not easily impressed. This app is perfect. The ads are short and unobtrusive/ there are no ads on the actual score page. The preset score options are really handy when the 7 year old wants to be score keeper. Finally I love that you have to confirm that the game is over before it takes you to the results screen in case the wrong number was clicked.',
        },
        {
          authorAvatar:
            'https://static.colindavid.net/0001/projects/13-rounds-score-sheet-watchicon.png',
          authorJob: 'Mobile Application',
          authorName: '13 Rounds Score Sheet',
          text:
            '<b>V. Jones</b><br>Absolutely love everything about this app! Especially the stats.',
        },
      ];

  const sectionTestimonialsItems = [_.shuffle(sectionTestimonialsItemsI18n)[0]];

  return (
    <Fragment>
      <Helmet>
        <html lang={i18n.language} />
        <title>{t('Home.meta.title')}</title>
        <meta content={t('Home.meta.keywords')} name="keywords" />
        <meta content={t('Home.meta.description')} name="description" />
        <meta content={t('Home.meta.title')} property="og:title" />
        <meta content="website" property="og:type" />
        <meta content="https://www.colindavid.net" property="og:url" />
        <meta content={t('Home.meta.description')} property="og:description" />
        <meta
          content="https://static.colindavid.net/0001/david-colin-portfolio.png"
          property="og:image"
        />
        <meta content="image/jpeg" property="og:image:type" />
        <meta content="1200" property="og:image:width" />
        <meta content="630" property="og:image:height" />
        <meta content="3050957121630771" property="fb:app_id" />
        <meta content="summary" name="twitter:card" />
        <meta content="https://www.colindavid.net" name="twitter:url" />
        <meta content={t('Home.meta.title')} name="twitter:title" />
        <meta content={t('Home.meta.description')} name="twitter:description" />
        <meta
          content="https://static.colindavid.net/0001/david-colin-portfolio.png"
          name="twitter:image"
        />
        <meta content="@david_colin" name="twitter:site" />
        <meta content="@david_colin" name="twitter:creator" />
        <link href="https://www.colindavid.net" rel="canonical" />
      </Helmet>
      <HomePage
        footerDescription={t('App.footer.description')}
        footerNetworks={[
          {
            icon: 'twitter',
            link: 'https://twitter.com/david_colin',
            name: 'Twitter',
          },
          {
            icon: 'linkedin',
            link: 'https://www.linkedin.com/in/dcolin',
            name: 'LinkedIn',
          },
          {
            icon: 'github',
            link: 'https://www.github.com/dcolin',
            name: 'GitHub',
          },
          {
            icon: 'instagram',
            link: 'https://www.instagram.com/davcolin',
            name: 'Instagram  ',
          },
        ]}
        footerTitle={t('App.footer.title')}
        footerCopyrightButtonLabel={t('App.footerCopyright.buttonLabel')}
        footerCopyrightButtonOnClick={() => {
          i18n.changeLanguage(i18n.language === 'en' ? 'fr' : 'en');
        }}
        footerCopyrightDescription={
          <Trans
            defaults="Made with <strong>❤︎</strong> © {{ year }} David Colin"
            i18nKey="App.footerCopyright.description"
            values={{ year: new Date().getFullYear() }}
          />
        }
        headerAvatarSrc="https://static.colindavid.net/0001/david-colin.jpg"
        headerMenuItems={[
          {
            href: '#about',
            label: t('App.header.menuItems.about'),
            onClick: (event) => scrollToElement({ event, id: 'section-about' }),
          },
          {
            href: '#projects',
            label: t('App.header.menuItems.projects'),
            onClick: (event) =>
              scrollToElement({ event, id: 'section-projects' }),
          },
          {
            href: '#testimonials',
            label: t('App.header.menuItems.testimonials'),
            onClick: (event) =>
              scrollToElement({ event, id: 'section-testimonials' }),
          },
          {
            href: '#services',
            label: t('App.header.menuItems.services'),
            onClick: (event) =>
              scrollToElement({ event, id: 'section-services' }),
          },
          {
            button: true,
            href: '#contact',
            label: t('App.header.menuItems.contact'),
            onClick: (event) =>
              scrollToElement({ event, id: 'section-contact' }),
          },
        ]}
        headerProfileJob={t('App.header.profileJob')}
        headerProfileOnClick={() => scroll.scrollToTop()}
        headerProfileName={t('App.header.profileName')}
        sectionAboutButtonLabel={t('Home.sectionAbout.buttonLabel')}
        sectionAboutButtonOnClick={(event) =>
          scrollToElement({ event, id: 'section-contact' })
        }
        sectionAboutDescription={t('Home.sectionAbout.description')}
        sectionAboutImageSource="https://static.colindavid.net/0001/computer.svg"
        sectionAboutTitle={t('Home.sectionAbout.title')}
        sectionContactAddress={t('Home.sectionContact.address')}
        sectionContactEmail="hello@colindavid.net"
        sectionContactEmailSubject={t('Home.sectionContact.emailSubject')}
        sectionContactTitle={t('Home.sectionContact.title')}
        sectionExperienceDescription={t('Home.sectionExperience.description', {
          nbYears,
        })}
        sectionExperienceTitle={t('Home.sectionExperience.title', {
          nbYears,
        })}
        sectionProjectsItems={[
          {
            icon: 'cellphone-iphone',
            image:
              'https://static.colindavid.net/0001/projects/13-rounds-score-sheet.png',
            label: '13 Rounds Score Sheet',
            link: 'https://13roundsscoresheet.colindavid.net',
            type: t('Home.sectionProjects.types.mobileApplication'),
          },
          {
            icon: 'tablet-ipad',
            image: 'https://static.colindavid.net/0001/projects/nabl-kiosk.png',
            label: 'Nabl Kiosk',
            type: t('Home.sectionProjects.types.tabletApplication'),
          },
          {
            icon: 'cellphone-iphone',
            image: 'https://static.colindavid.net/0001/projects/ciwik.png',
            label: 'Ciwik',
            link: 'https://www.ciwik.com',
            type: t('Home.sectionProjects.types.mobileApplication'),
          },
          {
            icon: 'cellphone-iphone',
            image:
              'https://static.colindavid.net/0001/projects/nutri-score-scan.png',
            label: 'Nutri Score Scan',
            link: 'https://nutriscorescan.colindavid.net',
            type: t('Home.sectionProjects.types.mobileApplication'),
          },
        ]}
        sectionProjectsTitle={t('Home.sectionProjects.title')}
        sectionServicesItems={[
          {
            description: t('Home.sectionServices.items.design.description'),
            details: [
              {
                label: t('Home.sectionServices.skills'),
                description:
                  'UX, Mockups, Wireframes, Prototyping, Atomic design',
              },
              {
                label: t('Home.sectionServices.tools'),
                description:
                  'Balsamiq Mockups<br />Invision<br />Marvel<br />Sketch<br />Webflow<br />Zeplin',
              },
            ],
            icon: 'image-edit',
            label: t('Home.sectionServices.items.design.label'),
          },
          {
            description: t(
              'Home.sectionServices.items.development.description',
            ),
            details: [
              {
                label: t('Home.sectionServices.skills'),
                description:
                  'React, React Native, GraphQL, Node.js, MongoDB, Vue.js, Android, iOS.',
              },
              {
                label: t('Home.sectionServices.tools'),
                description:
                  'Git<br />Visual Studio Code<br />Terminal<br />Xcode<br />Android Studio',
              },
            ],
            icon: 'code-braces-box',
            label: t('Home.sectionServices.items.development.label'),
          },
          {
            description: t(
              'Home.sectionServices.items.performance.description',
            ),
            details: [
              {
                label: t('Home.sectionServices.skills'),
                description:
                  'Server Side Rendering, Offline, Caching, SEO and ASO.',
              },
              {
                label: t('Home.sectionServices.tools'),
                description:
                  'Google Analytics<br />Firebase<br />Facebook Analytics<br />Lighthouse<br />Crashlytics',
              },
            ],
            icon: 'chart-bell-curve-cumulative',
            label: t('Home.sectionServices.items.performance.label'),
          },
        ]}
        sectionServicesTitle={t('Home.sectionServices.title')}
        sectionTestimonialsItems={sectionTestimonialsItems}
        sectionTestimonialsTitle={t('Home.sectionTestimonials.title')}
      />
    </Fragment>
  );
};

export default Home;
