import Footer from '../../Organisms/Footer';
import FooterCopyright from '../../Organisms/FooterCopyright';
import Header from '../../Organisms/Header';
import Page from '../../Organisms/Page';
import PropTypes from 'prop-types';
import React from 'react';
import SectionAbout from '../../Organisms/SectionAbout';
import SectionContact from '../../Organisms/SectionContact';
import SectionExperience from '../../Organisms/SectionExperience';
import SectionProjects from '../../Organisms/SectionProjects';
import SectionServices from '../../Organisms/SectionServices';
import SectionTestimonials from '../../Organisms/SectionTestimonials';

const Home = (props) => {
  const {
    footerDescription,
    footerCopyrightButtonLabel,
    footerCopyrightButtonOnClick,
    footerCopyrightDescription,
    footerNetworks,
    footerTitle,
    headerAvatarSrc,
    headerMenuItems,
    headerProfileJob,
    headerProfileOnClick,
    headerProfileName,
    sectionAboutButtonLabel,
    sectionAboutButtonOnClick,
    sectionAboutDescription,
    sectionAboutImageSource,
    sectionAboutTitle,
    sectionContactAddress,
    sectionContactEmail,
    sectionContactEmailSubject,
    sectionContactMobileNumber,
    sectionContactTitle,
    sectionExperienceDescription,
    sectionExperienceTitle,
    sectionProjectsItems,
    sectionProjectsTitle,
    sectionServicesItems,
    sectionServicesTitle,
    sectionTestimonialsItems,
    sectionTestimonialsTitle,
  } = props;

  return (
    <Page>
      <Header
        avatarSrc={headerAvatarSrc}
        menuItems={headerMenuItems}
        profileJob={headerProfileJob}
        profileOnClick={headerProfileOnClick}
        profileName={headerProfileName}
      />
      <SectionAbout
        buttonLabel={sectionAboutButtonLabel}
        buttonOnClick={sectionAboutButtonOnClick}
        description={sectionAboutDescription}
        imageSource={sectionAboutImageSource}
        title={sectionAboutTitle}
      />
      <SectionExperience
        description={sectionExperienceDescription}
        title={sectionExperienceTitle}
      />
      <SectionProjects
        items={sectionProjectsItems}
        title={sectionProjectsTitle}
      />
      <SectionTestimonials
        items={sectionTestimonialsItems}
        title={sectionTestimonialsTitle}
      />
      <SectionServices
        items={sectionServicesItems}
        title={sectionServicesTitle}
      />
      <SectionContact
        address={sectionContactAddress}
        email={sectionContactEmail}
        emailSubject={sectionContactEmailSubject}
        mobileNumber={sectionContactMobileNumber}
        title={sectionContactTitle}
      />
      <Footer
        description={footerDescription}
        networks={footerNetworks}
        title={footerTitle}
      />
      <FooterCopyright
        buttonLabel={footerCopyrightButtonLabel}
        buttonOnClick={footerCopyrightButtonOnClick}
        description={footerCopyrightDescription}
      />
    </Page>
  );
};

Home.propTypes = {
  footerDescription: PropTypes.string,
  footerCopyrightButtonLabel: PropTypes.string,
  footerCopyrightButtonOnClick: PropTypes.func,
  footerCopyrightDescription: PropTypes.node,
  footerNetworks: PropTypes.array,
  footerTitle: PropTypes.string,
  headerAvatarSrc: PropTypes.string,
  headerMenuItems: PropTypes.array,
  headerProfileJob: PropTypes.string,
  headerProfileOnClick: PropTypes.func,
  headerProfileName: PropTypes.string,
  sectionAboutButtonLabel: PropTypes.string,
  sectionAboutButtonOnClick: PropTypes.func,
  sectionAboutDescription: PropTypes.string,
  sectionAboutImageSource: PropTypes.string,
  sectionAboutTitle: PropTypes.string,
  sectionContactAddress: PropTypes.string,
  sectionContactEmail: PropTypes.string,
  sectionContactEmailSubject: PropTypes.string,
  sectionContactMobileNumber: PropTypes.string,
  sectionContactTitle: PropTypes.string,
  sectionExperienceDescription: PropTypes.string,
  sectionExperienceTitle: PropTypes.string,
  sectionProjectsItems: PropTypes.array,
  sectionProjectsTitle: PropTypes.string,
  sectionServicesItems: PropTypes.array,
  sectionServicesTitle: PropTypes.string,
  sectionTestimonialsItems: PropTypes.array,
  sectionTestimonialsTitle: PropTypes.string,
};

export default Home;
