import Button from '../../Atoms/Button';
import PropTypes from 'prop-types';
import React from 'react';
import Section from '../Section';
import { device } from '../../../config/breakpoint';
import styled from 'styled-components';

const StyledSectionButton = styled.div`
  margin-top: 3rem;
`;

const StyledSectionDescription = styled.p``;

const StyledSectionInformations = styled.div`
  @media ${device.mobileS} {
    grid-column: span 4;
  }
  @media ${device.tablet} {
    grid-column: span 8;
  }
  @media ${device.laptop} {
    grid-column: span 7;
  }
  @media ${device.laptopL} {
    grid-column: span 6;
  }
`;

const StyledSectionImage = styled.img`
  margin-top: 5rem;
  max-width: 100%;
  @media ${device.mobileS} {
    grid-column: span 4;
  }
  @media ${device.tablet} {
    grid-column: span 8;
  }
  @media ${device.laptop} {
    grid-column: span 5;
  }
  @media ${device.laptopL} {
    grid-column: span 6;
  }
`;

const StyledSectionTitle = styled.h1`
  @media ${device.mobileS} {
    font-size: 4rem;
  }
  @media ${device.mobileM} {
    font-size: 5rem;
  }
`;

const SectionAbout = (props) => {
  const { buttonLabel, buttonOnClick, description, imageSource, title } = props;

  if (!buttonLabel && !description && !imageSource && !title) {
    return null;
  }

  return (
    <Section id="section-about" viewportHeight="calc(100vh - 9rem)">
      <StyledSectionInformations>
        {title && <StyledSectionTitle>{title}</StyledSectionTitle>}
        {description && (
          <StyledSectionDescription
            dangerouslySetInnerHTML={{ __html: description }}
          />
        )}
        {buttonLabel && (
          <StyledSectionButton>
            <Button onClick={buttonOnClick} size="large">
              {buttonLabel}
            </Button>
          </StyledSectionButton>
        )}
      </StyledSectionInformations>
      {imageSource && <StyledSectionImage alt="" src={imageSource} />}
    </Section>
  );
};

SectionAbout.propTypes = {
  buttonLabel: PropTypes.string,
  buttonOnClick: PropTypes.func,
  description: PropTypes.string,
  imageSource: PropTypes.string,
  title: PropTypes.string,
};

export default SectionAbout;
