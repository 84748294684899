import Icon from '../../Atoms/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { color as themeColors } from '../../../config/theme';

export const icons = [
  'chart-bell-curve-cumulative',
  'code-braces-box',
  'image-edit',
];

const StyledService = styled.article`
  background-color: ${themeColors.grey02.hex};
  border-radius: 2rem;
  height: 100%;
  padding: 2rem;
  text-align: center;
  &::after,
  &::before {
    clear: both;
    content: '';
    display: table;
  }
`;

const StyledServiceDescription = styled.p``;

const StyledServiceLabel = styled.h2`
  font-size: 2.4rem;
`;

const StyledServiceIcon = styled.div``;

const StyledServiceDetail = styled.div``;
const StyledServiceDetailLabel = styled.h3`
  color: ${themeColors.secondary.hex};
  font-size: 1.8rem;
  margin-top: 3rem;
`;
const StyledServiceDetailDescription = styled.p``;

const Service = (props) => {
  const { description, details, icon, label } = props;

  return (
    <StyledService>
      {icon && (
        <StyledServiceIcon>
          <Icon icon={icon} size="large" />
        </StyledServiceIcon>
      )}
      {label && (
        <StyledServiceLabel dangerouslySetInnerHTML={{ __html: label }} />
      )}
      {description && (
        <StyledServiceDescription
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
      {details &&
        details.map((detail, key) => {
          const { label, description } = detail;
          return (
            <StyledServiceDetail key={key}>
              {label && (
                <StyledServiceDetailLabel>{label}</StyledServiceDetailLabel>
              )}
              {description && (
                <StyledServiceDetailDescription
                  dangerouslySetInnerHTML={{ __html: description }}
                />
              )}
            </StyledServiceDetail>
          );
        })}
    </StyledService>
  );
};

Service.defaultProps = {};

Service.propTypes = {
  description: PropTypes.string,
  details: PropTypes.array,
  icon: PropTypes.oneOf(icons),
  label: PropTypes.string,
};

export default Service;
