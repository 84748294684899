import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { color as themeColors } from '../../../config/theme';

const StyledMenuItem = styled.a`
  color: ${themeColors.grey03.hex};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  display: inline-block;
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  font-size: 1.8rem;
  font-weight: 400;
  padding-top: 0.9rem;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  &::after {
    background-color: ${themeColors.secondary.hex};
    border-radius: 0.4rem;
    display: block;
    content: '';
    height: 0.4rem;
    margin-top: 0.5rem;
    transition: width 0.2s ease-in-out;
    width: ${(props) => (props.active ? '100%' : 0)};
  }
  &:not([disabled]):hover {
    &::after {
      width: 100%;
    }
  }
`;

const MenuItem = (props) => {
  const { children } = props;

  return <StyledMenuItem {...props}>{children}</StyledMenuItem>;
};

MenuItem.defaultProps = {
  active: false,
  disabled: false,
};

MenuItem.propTypes = {
  active: PropTypes.bool,
  children: PropTypes.node,
  href: PropTypes.string,
  disabled: PropTypes.bool,
};

export default MenuItem;
