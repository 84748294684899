import { device } from '../../../config/breakpoint';
import styled from 'styled-components';

const Grid = styled.div`
  display: grid;
  margin: 0 auto;
  grid-column-gap: 2rem;
  @media ${device.mobileS} {
    grid-template-columns: repeat(4, 1fr);
    max-width: 30rem;
  }
  @media ${device.mobileM} {
    max-width: 34rem;
  }
  @media ${device.mobileL} {
    max-width: 38rem;
  }
  @media ${device.tablet} {
    grid-template-columns: repeat(8, 1fr);
    max-width: 64rem;
  }
  @media ${device.laptop} {
    grid-template-columns: repeat(12, 1fr);
    max-width: 96rem;
  }
  @media ${device.laptopL} {
    grid-template-columns: repeat(12, 1fr);
    max-width: 128rem;
  }
  @media ${device.desktop} {
    max-width: 160rem;
  }
`;

export default Grid;
