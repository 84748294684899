import PropTypes from 'prop-types';
import React from 'react';
import Section from '../Section';
import SectionTitle from '../../Atoms/SectionTitle';
import { device } from '../../../config/breakpoint';
import styled from 'styled-components';
import { color as themeColors } from '../../../config/theme';

const StyledSectionEmail = styled.a`
  display: block;
  color: ${themeColors.secondary.hex};
  font-size: 2.6rem;
  font-weight: 400;
  text-decoration: none;
`;

const StyledSectionMobileNumber = styled.a`
  color: ${themeColors.grey03.hex};
  font-size: 2.6rem;
  line-height: 3.8rem;
  font-weight: 400;
  text-decoration: none;
`;

const StyledSectionAddress = styled.p`
  color: ${themeColors.grey03.hex};
  font-size: 2.6rem;
  font-weight: 400;
  line-height: 3.8rem;
  opacity: 0.5;
  text-decoration: none;
  @media ${device.mobileS} {
    grid-column: span 4;
    text-align: center;
  }
  @media ${device.tablet} {
    text-align: left;
  }
  @media ${device.laptop} {
    grid-column: span 4;
  }
  @media ${device.laptopL} {
    grid-column: span 3;
  }
`;

const StyledSectionCoords = styled.div`
  align-items: center;
  display: flex;
  div {
    width: 100%;
  }
  @media ${device.mobileS} {
    grid-column: span 4;
    text-align: center;
  }
  @media ${device.tablet} {
    text-align: left;
  }
  @media ${device.laptop} {
    grid-column: 3 / span 4;
  }
  @media ${device.laptopL} {
    grid-column: 4 / span 3;
  }
`;

const StyledSectionTitle = styled.div`
  text-align: center;
  @media ${device.mobileS} {
    grid-column: span 4;
  }
  @media ${device.tablet} {
    grid-column: span 8;
  }
  @media ${device.laptop} {
    grid-column: span 12;
  }
`;

const SectionContact = (props) => {
  const { address, email, emailSubject, mobileNumber, title } = props;

  if (!address && !email && !emailSubject && !mobileNumber && !title) {
    return null;
  }

  return (
    <Section
      backgroundColor="grey02"
      id="section-contact"
      viewportHeight="30rem">
      {title && (
        <StyledSectionTitle>
          <SectionTitle>{title}</SectionTitle>
        </StyledSectionTitle>
      )}
      <StyledSectionCoords>
        <div>
          {email && (
            <StyledSectionEmail
              href={`mailto:${email}?subject=${encodeURI(emailSubject)}`}>
              {email}
            </StyledSectionEmail>
          )}
          {mobileNumber && (
            <StyledSectionMobileNumber
              href={`tel:+33${mobileNumber.replace(/ /g, '')}`}>
              {mobileNumber}
            </StyledSectionMobileNumber>
          )}
        </div>
      </StyledSectionCoords>
      <StyledSectionAddress dangerouslySetInnerHTML={{ __html: address }} />
    </Section>
  );
};

SectionContact.propTypes = {
  address: PropTypes.string,
  email: PropTypes.string,
  emailSubject: PropTypes.string,
  mobileNumber: PropTypes.string,
  title: PropTypes.string,
};

export default SectionContact;
