import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { color as themeColors } from '../../../config/theme';

export const colors = Object.keys(themeColors);
export const names = [
  'cellphone-iphone',
  'chart-bell-curve-cumulative',
  'code-braces-box',
  'desktop-mac',
  'github',
  'image-edit',
  'instagram',
  'linkedin',
  'menu',
  'tablet-ipad',
  'twitter',
];
export const sizes = ['small', 'medium', 'large', 'xlarge', 'xxlarge'];

const sizesStyles = {
  small: {
    fontSize: '2rem',
  },
  medium: {
    fontSize: '3rem',
  },
  large: {
    fontSize: '4rem',
  },
  xlarge: {
    fontSize: '5rem',
  },
  xxlarge: {
    fontSize: '6rem',
  },
};

const StyledIcon = styled.span`
  color: ${(props) => themeColors[props.theme].hex};
  font-size: ${(props) => sizesStyles[props.size].fontSize};
`;

const Icon = (props) => {
  const { icon } = props;

  return <StyledIcon {...props} className={`mdi mdi-${icon}`} />;
};

Icon.defaultProps = {
  size: 'medium',
  theme: 'primary',
};

Icon.propTypes = {
  icon: PropTypes.oneOf(names),
  size: PropTypes.oneOf(sizes),
  theme: PropTypes.oneOf(colors),
};

export default Icon;
