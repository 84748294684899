import Button from '../../Atoms/Button';
import PropTypes from 'prop-types';
import React from 'react';
import { device } from '../../../config/breakpoint';
import styled from 'styled-components';
import { color as themeColors } from '../../../config/theme';

const StyledFooterCopyright = styled.footer`
  align-items: center;
  background-color: ${themeColors.grey04.hex};
  display: flex;
  justify-content: center;
  min-height: 6rem;
  padding: 1rem 0;
  @media ${device.mobileS} {
    flex-direction: column;
  }
  @media ${device.tablet} {
    flex-direction: row;
  }
  @media ${device.laptop} {
  }
`;

const StyledFooterButton = styled.div`
  margin: 1rem;
`;

const StyledFooterDescription = styled.p`
  color: ${themeColors.white.hex};
  font-size: 1.4rem;
  margin: 1rem;
  b,
  strong {
    color: ${themeColors.secondary.hex};
  }
`;

const FooterCopyright = (props) => {
  const { buttonLabel, buttonOnClick, description } = props;

  if (!buttonLabel && !description) {
    return null;
  }

  return (
    <StyledFooterCopyright id="footer-copyright">
      {description && (
        <StyledFooterDescription>{description}</StyledFooterDescription>
      )}
      {buttonLabel && (
        <StyledFooterButton>
          <Button onClick={buttonOnClick} size="small">
            {buttonLabel}
          </Button>
        </StyledFooterButton>
      )}
    </StyledFooterCopyright>
  );
};

FooterCopyright.propTypes = {
  buttonLabel: PropTypes.string,
  buttonOnClick: PropTypes.func,
  description: PropTypes.node,
};

export default FooterCopyright;
