import Avatar from '../../Atoms/Avatar';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';

const StyledTestimonial = styled.article`
  padding: 2rem;
  text-align: center;
`;

const StyledTestimonialAuthor = styled.div`
  margin-top: 5rem;
`;
const StyledTestimonialAuthorAvatar = styled.div``;
const StyledTestimonialAuthorName = styled.h3`
  font-size: 2rem;
  margin-bottom: 0;
`;
const StyledTestimonialAuthorJob = styled.p`
  opacity: 0.5;
  margin-top: 0;
`;

const StyledTestimonialText = styled.p``;

const Testimonial = (props) => {
  const { authorAvatar, authorJob, authorName, text } = props;

  return (
    <StyledTestimonial>
      {text && (
        <StyledTestimonialText dangerouslySetInnerHTML={{ __html: text }} />
      )}
      <StyledTestimonialAuthor>
        {authorAvatar && (
          <StyledTestimonialAuthorAvatar>
            <Avatar src={authorAvatar} />
          </StyledTestimonialAuthorAvatar>
        )}
        {authorName && (
          <StyledTestimonialAuthorName>
            {authorName}
          </StyledTestimonialAuthorName>
        )}
        {authorJob && (
          <StyledTestimonialAuthorJob>{authorJob}</StyledTestimonialAuthorJob>
        )}
      </StyledTestimonialAuthor>
    </StyledTestimonial>
  );
};

Testimonial.defaultProps = {};

Testimonial.propTypes = {
  authorAvatar: PropTypes.string,
  authorJob: PropTypes.string,
  authorName: PropTypes.string,
  text: PropTypes.string,
};

export default Testimonial;
