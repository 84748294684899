import React from 'react';
import styled from 'styled-components';

const StyledPage = styled.div`
  padding-top: 9rem;
`;

const Page = (props) => {
  const { children } = props;

  return <StyledPage>{children}</StyledPage>;
};

export default Page;
