import PropTypes from 'prop-types';
import React from 'react';
import Section from '../Section';
import { device } from '../../../config/breakpoint';
import styled from 'styled-components';

const StyledSectionDescription = styled.p`
  @media ${device.mobileS} {
    grid-column: span 4;
  }
  @media ${device.tablet} {
    grid-column: span 8;
  }
  @media ${device.laptop} {
    grid-column: span 7;
    margin: 5rem 0;
  }
`;

const StyledSectionTitle = styled.h1`
  line-height: 4.4rem;
  margin: 5rem 0;
  small {
    font-size: 3.2rem;
    line-height: 1.8rem;
  }
  @media ${device.mobileS} {
    grid-column: span 4;
    margin-bottom: 1.5rem;
  }
  @media ${device.tablet} {
    grid-column: span 8;
  }
  @media ${device.laptop} {
    grid-column: 2 / span 3;
  }
`;

const SectionExperience = (props) => {
  const { description, title } = props;

  if (!description && !title) {
    return null;
  }

  return (
    <Section
      backgroundColor="tertiary"
      id="section-experience"
      viewportHeight="26rem">
      {title && (
        <StyledSectionTitle dangerouslySetInnerHTML={{ __html: title }} />
      )}
      {description && (
        <StyledSectionDescription
          dangerouslySetInnerHTML={{ __html: description }}
        />
      )}
    </Section>
  );
};

SectionExperience.propTypes = {
  title: PropTypes.node,
};

export default SectionExperience;
