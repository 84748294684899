import PropTypes from 'prop-types';
import React from 'react';
import Section from '../Section';
import SectionTitle from '../../Atoms/SectionTitle';
import Service from '../../Molecules/Service';
import { device } from '../../../config/breakpoint';
import styled from 'styled-components';

const StyledSectionService = styled.div`
  margin-bottom: 2rem;
  @media ${device.mobileS} {
    grid-column: span 4;
  }
  @media ${device.tablet} {
    grid-column: span 8;
  }
  @media ${device.laptop} {
    grid-column: span 4;
  }
`;

const StyledSectionTitle = styled.div`
  grid-column: span 12;
  margin-bottom: 3rem;
  text-align: center;
  @media ${device.mobileS} {
    grid-column: span 4;
  }
  @media ${device.tablet} {
    grid-column: span 8;
  }
  @media ${device.laptop} {
    grid-column: span 12;
  }
`;

const SectionServices = (props) => {
  const { items, title } = props;

  if (!items && !title) {
    return null;
  }

  return (
    <Section id="section-services" viewportHeight="auto">
      {title && (
        <StyledSectionTitle>
          <SectionTitle>{title}</SectionTitle>
        </StyledSectionTitle>
      )}
      {items &&
        items.map((service, key) => {
          return (
            <StyledSectionService first={key === 0} key={`service-${key}`}>
              <Service {...service} />
            </StyledSectionService>
          );
        })}
    </Section>
  );
};

SectionServices.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
};

export default SectionServices;
