import Grid from '../Grid';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { color as themeColors } from '../../../config/theme';

const StyledSection = styled.section`
  align-items: center;
  background-color: ${(props) => themeColors[props.backgroundColor].hex};
  color: ${themeColors.grey03.hex};
  display: grid;
  min-height: ${(props) => props.viewportHeight};
`;

const StyledSectionInner = styled.div`
  padding: 2rem 0 4rem 0;
`;

const Section = (props) => {
  const { backgroundColor, children, id, viewportHeight } = props;

  return (
    <StyledSection
      backgroundColor={backgroundColor}
      id={id}
      viewportHeight={viewportHeight}>
      <StyledSectionInner>
        <Grid>{children}</Grid>
      </StyledSectionInner>
    </StyledSection>
  );
};

Section.defaultProps = {
  backgroundColor: 'white',
  viewportHeight: '100vh',
};

Section.propTypes = {
  backgroundColor: PropTypes.oneOf(Object.keys(themeColors)),
  id: PropTypes.string,
  viewportHeight: PropTypes.string,
};

export default Section;
