import Avatar from '../../Atoms/Avatar';
import Grid from '../Grid';
import Menu from '../../Molecules/Menu';
import PropTypes from 'prop-types';
import React from 'react';
import { device } from '../../../config/breakpoint';
import styled from 'styled-components';
import { color as themeColors } from '../../../config/theme';

const StyledHeader = styled.header`
  align-items: center;
  background-color: ${themeColors.white.hex};
  box-shadow: 0 0 1rem 0 rgba(208, 205, 225, 0.5);
  display: grid;
  min-height: 9rem;
  left: 0;
  position: fixed;
  right: 0;
  top: 0;
  z-index: 2;
`;

const StyledHeaderInner = styled.div``;

const StyledHeaderAvatar = styled.div`
  grid-column: span 1;
`;

const StyledHeaderProfile = styled.div`
  display: flex;
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  flex-direction: column;
  grid-column: span 3;
  justify-content: center;
  @media ${device.mobileS} {
    grid-column: span 3;
  }
  @media ${device.tablet} {
    grid-column: span 7;
  }
  @media ${device.laptop} {
    grid-column: span 3;
  }
`;

const StyledHeaderProfileJob = styled.p`
  font-size: 1.4rem;
  line-height: 1.7rem;
  opacity: 0.5;
  margin: 0;
`;

const StyledHeaderProfileName = styled.p`
  line-height: 2.6rem;
  font-size: 2.2rem;
  font-weight: 700;
  margin: 0;
  text-transform: uppercase;
`;

const StyledHeaderMenu = styled.div`
  align-items: center;
  justify-content: flex-end;
  @media ${device.mobileS} {
    display: none;
  }
  @media ${device.laptop} {
    display: flex;
    grid-column: 5 / span 12;
  }
`;

const Header = (props) => {
  const {
    avatarSrc,
    menuItems,
    profileJob,
    profileOnClick,
    profileName,
  } = props;

  if (!avatarSrc && !menuItems && !profileJob && !profileName) {
    return null;
  }

  return (
    <StyledHeader id="header">
      <StyledHeaderInner>
        <Grid>
          {avatarSrc && (
            <StyledHeaderAvatar>
              <Avatar onClick={profileOnClick} src={avatarSrc} />
            </StyledHeaderAvatar>
          )}
          {(profileName || profileJob) && (
            <StyledHeaderProfile onClick={profileOnClick}>
              {profileName && (
                <StyledHeaderProfileName
                  dangerouslySetInnerHTML={{ __html: profileName }}
                />
              )}
              {profileJob && (
                <StyledHeaderProfileJob
                  dangerouslySetInnerHTML={{ __html: profileJob }}
                />
              )}
            </StyledHeaderProfile>
          )}
          {menuItems && (
            <StyledHeaderMenu>
              <Menu items={menuItems} />
            </StyledHeaderMenu>
          )}
        </Grid>
      </StyledHeaderInner>
    </StyledHeader>
  );
};

Header.propTypes = {
  avatarSrc: PropTypes.string,
  menuItems: PropTypes.array,
  profileJob: PropTypes.string,
  profileOnClick: PropTypes.func,
  profileName: PropTypes.string,
};

export default Header;
