import Icon from '../../Atoms/Icon';
import PropTypes from 'prop-types';
import React from 'react';
import { device } from '../../../config/breakpoint';
import styled from 'styled-components';
import { color as themeColors } from '../../../config/theme';

export const icons = ['cellphone-iphone', 'desktop-mac', 'tablet-ipad'];

const StyledProject = styled.article`
  background-color: ${themeColors.grey02.hex};
  background-image: ${(props) =>
    props.image ? `url(${props.image})` : 'none'};
  background-position: center center;
  background-repeat: no-repeat;
  background-size: cover;
  border-radius: 2rem;
  display: inline-block;
  min-height: 33rem;
  position: relative;
  width: 100%;
  z-index: 1;
`;

const StyledProjectInformations = styled.a`
  background-color: transparent;
  border-radius: 2rem;
  bottom: 0;
  left: 0;
  overflow: hidden;
  position: absolute;
  right: 0;
  text-decoration: none;
  transition: all 0.2s ease-in-out;
  top: 0;
  z-index: 2;
  &:hover {
    background-color: ${themeColors.grey03.hex}e6;
    .informations-card {
      bottom: 4rem;
      opacity: 1;
    }
  }
  @media ${device.mobileS} {
    &:hover {
      .informations-card {
        bottom: 2rem;
      }
    }
  }
  @media ${device.tablet} {
    &:hover {
      .informations-card {
        bottom: 4rem;
      }
    }
  }
`;
const StyledProjectInformationsCard = styled.div`
  align-items: center;
  background-color: ${themeColors.white.hex};
  border-radius: 1rem;
  bottom: 0;
  display: flex;
  opacity: 0;
  padding: 2rem;
  position: absolute;
  transition: all 0.2s ease-in-out;
  @media ${device.mobileS} {
    left: 2rem;
    padding: 1rem;
    right: 2rem;
  }
  @media ${device.tablet} {
    left: 4rem;
    padding: 2rem;
    right: 4rem;
  }
`;
const StyledProjectIcon = styled.div`
  margin-right: 1.5rem;
`;
const StyledProjectTexts = styled.div``;
const StyledProjectLabel = styled.h2`
  margin: 0;
  @media ${device.mobileS} {
    font-size: 2rem;
  }
  @media ${device.tablet} {
    font-size: 3rem;
  }
`;
const StyledProjectType = styled.p`
  color: ${themeColors.secondary.hex};
  font-weight: 400;
  margin: 0;
  @media ${device.mobileS} {
    font-size: 1.4rem;
    line-height: 2.4rem;
  }
  @media ${device.tablet} {
    font-size: 1.8rem;
    line-height: 2.8rem;
  }
`;

const Project = (props) => {
  const { icon, image, label, link, type } = props;

  return (
    <StyledProject image={image}>
      <StyledProjectInformations href={link}>
        <StyledProjectInformationsCard className="informations-card">
          {icon && (
            <StyledProjectIcon>
              <Icon icon={icon} theme="secondary" size="xxlarge" />
            </StyledProjectIcon>
          )}
          <StyledProjectTexts>
            {type && <StyledProjectType>{type}</StyledProjectType>}
            {label && <StyledProjectLabel>{label}</StyledProjectLabel>}
          </StyledProjectTexts>
        </StyledProjectInformationsCard>
      </StyledProjectInformations>
    </StyledProject>
  );
};

Project.defaultProps = {};

Project.propTypes = {
  icon: PropTypes.oneOf(icons),
  label: PropTypes.string,
  link: PropTypes.string,
  image: PropTypes.string,
  type: PropTypes.string,
};

export default Project;
