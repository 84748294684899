import Button from '../../Atoms/Button';
import Grid from '../Grid';
import PropTypes from 'prop-types';
import React from 'react';
import { device } from '../../../config/breakpoint';
import styled from 'styled-components';
import { color as themeColors } from '../../../config/theme';

const StyledFooter = styled.footer`
  background-color: ${themeColors.grey03.hex};
  min-height: 29rem;
`;

const StyledFooterHeading = styled.div`
  margin-top: 5rem;
  text-align: center;
  @media ${device.mobileS} {
    grid-column: span 4;
  }
  @media ${device.tablet} {
    grid-column: span 8;
  }
  @media ${device.laptop} {
    grid-column: span 12;
  }
`;

const StyledFooterTitle = styled.p`
  color: ${themeColors.white.hex};
  font-size: 2.6rem;
  font-weight: 700;
  margin: 0;
`;

const StyledFooterDescription = styled.p`
  color: ${themeColors.white.hex};
  margin: 0;
  opacity: 0.5;
`;

const StyledFooterNetworks = styled.div`
  align-items: center;
  display: flex;
  margin-top: 5rem;
  justify-content: center;
  text-align: center;
  @media ${device.mobileS} {
    grid-column: span 4;
  }
  @media ${device.tablet} {
    grid-column: span 8;
  }
  @media ${device.laptop} {
    grid-column: span 12;
  }
`;

const StyledFooterNetwork = styled.div`
  margin: 2rem 1rem;
`;

const Footer = (props) => {
  const { description, networks, title } = props;

  if (!description && !networks && !title) {
    return null;
  }

  return (
    <StyledFooter id="footer">
      <Grid>
        <StyledFooterHeading>
          {title && <StyledFooterTitle>{title}</StyledFooterTitle>}
          {description && (
            <StyledFooterDescription>{description}</StyledFooterDescription>
          )}
        </StyledFooterHeading>
        <StyledFooterNetworks>
          {networks &&
            networks.map((network, key) => {
              const { icon, name } = network;
              return (
                <StyledFooterNetwork key={`network-${key}`}>
                  <Button aria-label={name} icon={icon} variant="icon" />
                </StyledFooterNetwork>
              );
            })}
        </StyledFooterNetworks>
      </Grid>
    </StyledFooter>
  );
};

Footer.propTypes = {
  description: PropTypes.string,
  networks: PropTypes.array,
  title: PropTypes.string,
};

export default Footer;
