import PropTypes from 'prop-types';
import React from 'react';
import Section from '../Section';
import SectionTitle from '../../Atoms/SectionTitle';
import Testimonial from '../../Molecules/Testimonial';
import { device } from '../../../config/breakpoint';
import styled from 'styled-components';

const StyledSectionTestimonial = styled.div`
  @media ${device.mobileS} {
    grid-column: span 4;
  }
  @media ${device.tablet} {
    grid-column: span 8;
  }
  @media ${device.laptop} {
    grid-column: 3 / span 8;
  }
`;

const StyledSectionTitle = styled.div`
  text-align: center;
  @media ${device.mobileS} {
    grid-column: span 4;
  }
  @media ${device.tablet} {
    grid-column: span 8;
  }
  @media ${device.laptop} {
    grid-column: span 12;
  }
`;

const SectionTestimonials = (props) => {
  const { items, title } = props;

  if (!items && !title) {
    return null;
  }

  return (
    <Section
      backgroundColor="grey02"
      id="section-testimonials"
      viewportHeight="60rem">
      {title && (
        <StyledSectionTitle>
          <SectionTitle>{title}</SectionTitle>
        </StyledSectionTitle>
      )}
      {items &&
        items.map((testimonial, key) => {
          return (
            <StyledSectionTestimonial key={`testimonial-${key}`}>
              <Testimonial {...testimonial} />
            </StyledSectionTestimonial>
          );
        })}
    </Section>
  );
};

SectionTestimonials.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
};

export default SectionTestimonials;
