import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { color as themeColors } from '../../../config/theme';

const StyledSectionTitle = styled.h1`
  display: inline-block;
  font-size: 4rem;
  &::after {
    background-color: ${themeColors.secondary.hex};
    border-radius: 0.6rem;
    content: '';
    display: block;
    height: 0.6rem;
    margin-left: 50%;
    margin-top: 1rem;
    transform: translateX(-50%);
    width: 50%;
  }
`;

const SectionTitle = (props) => {
  const { children } = props;

  return <StyledSectionTitle {...props}>{children}</StyledSectionTitle>;
};

SectionTitle.defaultProps = {};

SectionTitle.propTypes = {
  children: PropTypes.node,
};

export default SectionTitle;
