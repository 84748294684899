import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { color as themeColors } from '../../../config/theme';

export const sizes = ['small', 'medium', 'large'];

const sizesStyles = {
  small: {
    borderRadius: '5rem',
    height: '5rem',
    width: '5rem',
  },
  medium: {
    borderRadius: '7.5rem',
    height: '7.5rem',
    width: '7.5rem',
  },
  large: {
    borderRadius: '10rem',
    height: '10rem',
    width: '10rem',
  },
};

const StyledAvatar = styled.img`
  border-color: ${themeColors.tertiary.hex};
  border-style: solid;
  border-width: ${(props) => (props.border ? '0.2rem' : 0)};
  border-radius: ${(props) => sizesStyles[props.size].borderRadius};
  cursor: ${(props) => (props.onClick ? 'pointer' : 'default')};
  height: ${(props) => sizesStyles[props.size].height};
  transition: all 0.2s ease-in-out;
  width: ${(props) => sizesStyles[props.size].width};
`;

const Avatar = (props) => {
  return <StyledAvatar {...props} />;
};

Avatar.defaultProps = {
  alt: '',
  border: true,
  size: 'medium',
};

Avatar.propTypes = {
  alt: PropTypes.string,
  border: PropTypes.bool,
  onClick: PropTypes.func,
  src: PropTypes.string,
  size: PropTypes.oneOf(sizes),
};

export default Avatar;
