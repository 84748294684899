import Project from '../../Molecules/Project';
import PropTypes from 'prop-types';
import React from 'react';
import Section from '../Section';
import SectionTitle from '../../Atoms/SectionTitle';
import { device } from '../../../config/breakpoint';
import styled from 'styled-components';

const StyledSectionProject = styled.div`
  margin-bottom: 2rem;
  @media ${device.mobileS} {
    grid-column: span 4;
  }
  @media ${device.tablet} {
    grid-column: span 8;
  }
  @media ${device.laptop} {
    grid-column: span 6;
  }
`;

const StyledSectionTitle = styled.div`
  margin-bottom: 3rem;
  text-align: center;
  @media ${device.mobileS} {
    grid-column: span 4;
  }
  @media ${device.tablet} {
    grid-column: span 8;
  }
  @media ${device.laptop} {
    grid-column: span 12;
  }
`;

const SectionProjects = (props) => {
  const { items, title } = props;

  if (!items && !title) {
    return null;
  }

  return (
    <Section id="section-projects" viewportHeight="auto">
      {title && (
        <StyledSectionTitle>
          <SectionTitle>{title}</SectionTitle>
        </StyledSectionTitle>
      )}
      {items &&
        items.map((project, key) => {
          return (
            <StyledSectionProject key={`project-${key}`}>
              <Project {...project} />
            </StyledSectionProject>
          );
        })}
    </Section>
  );
};

SectionProjects.propTypes = {
  items: PropTypes.array,
  title: PropTypes.string,
};

export default SectionProjects;
