import Icon from '../Icon';
import PropTypes from 'prop-types';
import React from 'react';
import styled from 'styled-components';
import { color as themeColors } from '../../../config/theme';

export const colors = ['primary', 'secondary', 'vert'];
export const icons = ['facebook', 'github', 'instagram', 'linkedin', 'twitter'];
export const sizes = ['small', 'medium', 'large'];
export const variants = ['contained', 'icon', 'outlined'];

const sizesStyles = {
  small: {
    borderRadius: '3rem',
    fontSize: '1.4rem',
    minHeight: '3rem',
    padding: '0 2rem',
  },
  medium: {
    borderRadius: '4rem',
    fontSize: '1.8rem',
    minHeight: '4rem',
    padding: '0 3rem',
  },
  large: {
    borderRadius: '5rem',
    fontSize: '2.2rem',
    minHeight: '5rem',
    padding: '0 4rem',
  },
};

const StyledButton = styled.button`
  background-color: ${(props) =>
    props.variant === 'contained'
      ? themeColors[props.theme].hex
      : 'transparent'};
  border: 0.2rem solid
    ${(props) =>
      props.variant === 'icon'
        ? 'rgba(255,255,255,0.5)'
        : themeColors[props.theme].hex};
  border-radius: ${(props) => sizesStyles[props.size].borderRadius};
  color: ${(props) =>
    props.variant === 'icon'
      ? 'white'
      : themeColors[props.variant === 'contained' ? 'white' : props.theme].hex};
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  font-family: 'Montserrat', sans-serif;
  font-size: ${(props) => sizesStyles[props.size].fontSize};
  font-weight: 400;
  min-height: ${(props) =>
    props.variant === 'icon' ? '5rem' : sizesStyles[props.size].minHeight};
  opacity: ${(props) => (props.disabled ? 0.5 : 1)};
  padding: ${(props) =>
    props.variant === 'icon' ? 0 : sizesStyles[props.size].padding};
  transition: all 0.2s ease-in-out;
  min-width: ${(props) => (props.variant === 'icon' ? '5rem' : 'auto')};
`;

const Button = (props) => {
  const { children, icon, variant } = props;
  return (
    <StyledButton {...props}>
      {icon && <Icon icon={icon} size="medium" theme="white" />}{' '}
      {variant !== 'icon' && children}
    </StyledButton>
  );
};

Button.defaultProps = {
  disabled: false,
  size: 'medium',
  theme: 'primary',
  variant: 'contained',
};

Button.propTypes = {
  children: PropTypes.node,
  /**
   Use the disabled prop to indicate that the button is active or not.
   */
  disabled: PropTypes.bool,
  icon: PropTypes.oneOf(icons),
  size: PropTypes.oneOf(sizes),
  theme: PropTypes.oneOf(colors),
  variant: PropTypes.oneOf(variants),
};

export default Button;
