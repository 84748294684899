import Button from '../../Atoms/Button';
import MenuItem from '../../Atoms/MenuItem';
import React from 'react';
import { device } from '../../../config/breakpoint';
import styled from 'styled-components';

const StyledMenu = styled.div`
  align-items: center;
  display: flex;
`;

const StyledMenuItem = styled.div`
  @media ${device.laptop} {
    margin: 0 1.5rem;
  }
  @media ${device.laptopL} {
    margin: 0 2rem;
  }
`;

const Menu = (props) => {
  const { items } = props;

  if (!items) {
    return null;
  }

  return (
    <StyledMenu>
      {items.map((item, key) => {
        const { button, href, label, onClick } = item;
        return (
          <StyledMenuItem key={key}>
            {button ? (
              <Button onClick={onClick} variant="outlined">
                {label}
              </Button>
            ) : (
              <MenuItem href={href} onClick={onClick}>
                {label}
              </MenuItem>
            )}
          </StyledMenuItem>
        );
      })}
    </StyledMenu>
  );
};

export default Menu;
